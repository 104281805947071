import React, { useState, useRef } from "react";
import MiniSearch from "minisearch";
import { dbSearch } from "./dataSearch2";
import { withRouter, Link } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";
import "./searchengine.scss";
import { useHistory } from "react-router-dom";

const SearchEngineMarketAdvisor = () => {
  // const [selected, setSelected] = useState(props.preset);
  const [results, setResults] = useState([]);
  let history = useHistory();
  const ref = useRef();

  let miniSearch = new MiniSearch({
    fields: ["n", "s"], // fields to index for full-text search
    storeFields: ["n", "s", "c"], // fields to return with search results
  });

  miniSearch.addAll(dbSearch);

  function handleClick({ id, symbol }) {
    if (id < 30000) {
      history.push("/symbol/" + symbol);
    } else {
      history.push("/etf/" + symbol);
    }
    history.go(0);
  }

  const search = (e) => {
    const keyWord = e;
    let results = miniSearch.search(keyWord, { prefix: true });

    setResults(results.slice(0, 4));
  };

  return (
    <div>
      <Typeahead
        ref={ref}
        id="searchGeneral"
        onChange={() => ref.current?.clear()}
        onInputChange={(text) => {
          search(text);
        }}
        labelKey={(option) => `${option.n} ${option.s}`}
        options={results}
        placeholder="Search (ex: AAPL, Apple, MSCI WORLD, ISIN)"
        renderMenuItemChildren={(option) => (
          <Link to={`${process.env.PUBLIC_URL}/${option.id > 30000 ? "etf" : "symbol"}/${option.s}`}>
            <div style={{ color: "initial" }}>
              {option.id < 30000 ? option.n : option.n.slice(0, -14)}

              {option.id < 30000 ? (
                <div>
                  <small>
                    (Equity) - {option.s} - {option.c}
                  </small>
                </div>
              ) : (
                <div>
                  <small>{`(ETF) - ISIN: ${option.n.slice(-12)} - ${option.s}`}</small>
                </div>
              )}
            </div>
          </Link>
        )}
      />
    </div>
  );
};

export default withRouter(SearchEngineMarketAdvisor);
