export const dataTableComparatif = [
  {
    id: 0,
    ratio: "Sector",
    sign: "",
    variable: "sector",
    condition2: "none",
    condition1: "none",
  },
  {
    id: 100,
    ratio: "Industry",
    sign: "",
    variable: "industry",
    condition2: "none",
    condition1: "none",
  },
  {
    id: 1,
    ratio: "Gross Margin",
    plot: true,
    sign: "%",
    variable: "grossMargin",
    condition2: "none",
    condition1: "max",
  },

  {
    id: 2,
    ratio: "Operating Margin",
    plot: true,
    sign: "%",
    variable: "operatingMargin",
    condition2: "none",
    condition1: "max",
  },
  {
    id: 3,
    ratio: "Net Margin",
    sign: "%",
    plot: true,
    variable: "netMargin",
    condition2: "none",
    condition1: "max",
  },
  {
    id: 4,
    ratio: "ROA",
    plot: true,
    sign: "%",
    variable: "ROA",
    condition2: "none",
    condition1: "max",
  },
  {
    id: 5,
    ratio: "ROE",
    plot: true,
    sign: "%",
    variable: "ROE",
    condition2: "none",
    condition1: "max",
  },
  {
    id: 6,
    ratio: "Interest Cov",
    sign: "",
    variable: "interestCoverageRatio",
    condition2: "none",
    condition1: "fix_sup_100",
  },
  {
    id: 7,
    ratio: "Quick Ratio",
    sign: "%",
    variable: "quickRatio",
    condition2: "none",
    condition1: "fix_sup_100",
  },

  {
    id: 8,
    ratio: "E/A",
    sign: "%",
    variable: "eqAssetRatio",
    condition2: "none",
    condition1: "max",
  },

  {
    id: 9,
    ratio: "Forward P/E",
    sign: "",
    variable: "forwardPE",
    condition2: "none",
    condition1: "min",
  },
  {
    id: 10,
    ratio: "Trailing P/E",
    sign: "",
    variable: "trailingPE",
    condition2: "none",
    condition1: "min",
  },

  {
    id: 11,
    ratio: "P/Sales",
    sign: "",
    variable: "priceToSalesTrailing12Months",
    condition2: "none",
    condition1: "min",
  },
  {
    id: 12,
    ratio: "Dividend Yield",
    sign: "%",
    variable: "dividendYield",
    condition2: "none",
    condition1: "max",
  },
];
