import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/analytics";

const config = {
  firebase: {
    apiKey: "AIzaSyDlzD3Y-pI8rFKOFgVJXEAobZdNXyKYmpg",
    authDomain: "marketadvisor-1234.firebaseapp.com",
    databaseURL: "https://marketadvisor-1234-default-rtdb.firebaseio.com",
    projectId: "marketadvisor-1234",
    storageBucket: "marketadvisor-1234.appspot.com",
    messagingSenderId: "72164512746",
    appId: "1:72164512746:web:26fb24a15bdde2f7e81893",
    measurementId: "G-7VNBF79GJR",
  },
};
// Firebase

export const firebase_app = firebase.initializeApp({
  apiKey: config.firebase.apiKey,
  authDomain: config.firebase.authDomain,
  databaseURL: config.firebase.databaseURL,
  projectId: config.firebase.projectId,
  storageBucket: config.firebase.storageBucket,
  messagingSenderId: config.firebase.messagingSenderId,
  appId: config.firebase.appId,
});

// export const functions = firebase.functions(firebase_app);
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const analytics = firebase.analytics();
// export const facebookProvider = new firebase.auth.FacebookAuthProvider();
// export const twitterProvider = new firebase.auth.TwitterAuthProvider();
// export const githubProvider = new firebase.auth.GithubAuthProvider();
// export const db = firebase.firestore();
export const FieldValue = firebase.firestore.FieldValue;
// Auth0
