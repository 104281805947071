import React from "react";

const Loader = ({ fullScreen }) => {
  if (fullScreen) {
    return (
      <div style={{ position: "absolute", bottom: "50%", left: "50%", transform: "translate(-50%,0)" }}>
        <div className="text-center m-t-50 m-b-50">
          <span className="loader"></span>
          <h3 className="m-t-20" style={{ color: "#black" }}>
            <span>
              market<span style={{ opacity: "0.6" }}>advisor</span>
            </span>
          </h3>
        </div>
      </div>
    );
  } else {
    return (
      <div className="card col-sm-12 col-md-12 p-l-0 p-r-0">
        <div className="container p-l-0 p-r-0">
          <div className="text-center m-t-50 m-b-50">
            <span className="loader"></span>
          </div>
        </div>
      </div>
    );
  }
};

export default Loader;
