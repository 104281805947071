import { autocomplete } from "@algolia/autocomplete-js";
import Typesense from "typesense";
import { SearchResponseAdapter } from "typesense-instantsearch-adapter/lib/SearchResponseAdapter";
// import "@algolia/autocomplete-theme-classic";
import "@algolia/autocomplete-theme-classic/dist/theme.css";

import React, { Component } from "react";

class FunctionSearch extends Component {
  state = {};

  componentDidMount = () => {
    this.init_search_field();
  };

  init_search_field = () => {
    const client = this.typesense_client();
    // const adapter = typesense_adapter()
    autocomplete({
      debug: false,
      container: `#${this.props.idSearch}`,
      getSources: ({ query }) => [
        {
          sourceId: "stocks",
          templates: {
            item({ item, components, html }) {
              return html`
                <a style="color: black" href="${process.env.PUBLIC_URL}/symbol/${item.symbol}"
                  ><div>
                    <img src="${item.logo}" width="20" />
                    <span> </span>
                    <strong>
                      ${components.Snippet({
                        hit: item,
                        attribute: "symbol",
                      })}</strong
                    >
                    -
                    ${components.Highlight({
                      hit: item,
                      attribute: "name",
                    })}
                  </div>
                </a>
              `;
            },
          },
          getItems: () =>
            client
              .collections("stocks")
              .documents()
              .search({
                q: query,

                query_by: "symbol,name",
                sort_by: "rank:asc",
                query_by_weights: "1,3",
                highlight_full_fields: "name",
                highlight_start_tag: "__aa-highlight__",
                highlight_end_tag: "__/aa-highlight__",
              })
              .then((result) => {
                return this.search_response_adapter(result).adapt().hits;
              }),
          getItemInputValue: ({ item }) => {
            return item.name;
          },
        },
      ],
    });
  };

  search_response_adapter = (result) => new SearchResponseAdapter(result, { params: {} }, { geoLocationField: "_geoloc" });

  typesense_client = () =>
    new Typesense.Client({
      apiKey: "iKMKpv5iN8gLAJIbHeuuBDXzIUYDr5O8",
      nodes: [
        {
          host: "atbiwpryh4g75qz0p-1.a1.typesense.net",
          port: "443",
          protocol: "https",
        },
      ],
      connectionTimeoutSeconds: 2,
    });

  render() {
    return <div id={this.props.idSearch}></div>;
  }
}

export default FunctionSearch;
