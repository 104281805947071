import React, { useState, useEffect } from "react";
import man from "../assets/images/dashboard/user.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router";
import { firebase_app, googleProvider } from "../data/config";
import GoogleLogo from "./googleIcon.svg";
import Background from "../assets/background/background.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/fontawesome-free-regular";

const Signin = ({ history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [value, setValue] = useState(localStorage.getItem("profileURL" || man));
  const [inApp, setInApp] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  useEffect(() => {
    setInApp(isFacebookApp());
  }, []);

  function isFacebookApp() {
    var ua = navigator.userAgent || navigator.vendor || window.opera;
    return ua.indexOf("FBAN") > -1 || ua.indexOf("FBAV") > -1;
  }

  useEffect(() => {
    if (value !== null) localStorage.setItem("profileURL", value);
    else localStorage.setItem("profileURL", man);
  }, [value]);

  const loginAuth = async () => {
    try {
      await firebase_app.auth().signInWithEmailAndPassword(email, password);
      setValue(man);
      // localStorage.setItem("token", Jwt_token);
      history.push(`${process.env.PUBLIC_URL}/`);
    } catch (error) {
      setTimeout(() => {
        toast.error("E-mail ou mot de passe invalide");
      }, 200);
    }
  };

  const googleAuth = async () => {
    if (inApp) {
      setTimeout(() => {
        toast.error("Authentication with Google not allowed within the Facebook/Messenger/Instagram app. Open the site in your default browser.");
      }, 200);
    } else {
      try {
        firebase_app
          .auth()
          .signInWithPopup(googleProvider)
          .then(function (result) {
            // localStorage.setItem("token", Jwt_token);
            setTimeout(() => {
              history.push(`${process.env.PUBLIC_URL}/`);
            }, 200);
          });
      } catch (error) {
        setTimeout(() => {
          toast.error("Email ou mot de passe invalide");
        }, 200);
      }
    }
  };

  return (
    <div>
      {/* <Background style={{ position: "absolute", width: "100%", height: "auto" }} /> */}
      <div style={{ backgroundImage: `url(${Background})`, width: "100%", height: "100%", position: "absolute", opacity: "5%" }}></div>
      <div className="page-wrapper">
        <div className="container-fluid p-0">
          {/* <!-- login page start--> */}
          <div className="authentication-main">
            <div className="row">
              <div className="col-md-12">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <div className="text-center">
                      <h1>
                        <span>
                          market<span style={{ opacity: "0.6" }}>advisor</span>
                        </span>
                      </h1>
                    </div>
                    <div className="card mt-4">
                      <div className="card-body">
                        <div className="text-center">
                          <h4>{"Sign In"}</h4>
                        </div>
                        <form className="theme-form">
                          <div className="form-group">
                            <label className="h6">{"E-mail"}</label>
                            <input className="form-control" type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email address" />
                          </div>
                          <div className="form-group">
                            <label className="h6">{"Password"}</label>
                            <div style={{ display: "flex", position: "relative" }}>
                              <input className="form-control" type={passwordShown ? "text" : "password"} name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                              <FontAwesomeIcon onClick={togglePasswordVisiblity} icon={faEye} style={{ position: "absolute", top: "30%", right: "4%" }} />
                            </div>

                            <a href="/pages/resetPwd">Forgot your password ?</a>
                          </div>

                          <div className="form-group form-row mt-3 mb-0">
                            <button className="btn btnPrimary btn-block" type="button" onClick={() => loginAuth()}>
                              {"Sign In"}
                            </button>
                            <button className={inApp ? "btn btn-light btnPrimary btn-block disabled" : "btn btn-light btnPrimary btn-block"} type="button" onClick={googleAuth}>
                              <img src={GoogleLogo} alt="img_Google" style={{ height: "20px" }} />
                              {/* <i className="fa fa-google"> </i> */}
                              {"  Continue with Google"}
                            </button>
                          </div>
                        </form>
                        <div className="m-t-50">
                          <a className="btn btnPrimary btn-block" href="/pages/signup">
                            {"No account ? Join us !"}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
          {/* <!-- login page end--> */}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Signin);
