import React, { Component, Fragment } from "react";
import FunctionSearch from "./functionSearch";

class SearchStockComponent extends Component {
  state = {};
  render() {
    return (
      <Fragment>
        <div className="container">
          <div className="col-sm-12 col-xl-12">
            <FunctionSearch idSearch={"test"} />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default SearchStockComponent;
