import React, { useState, Fragment, useEffect, useContext } from "react";
import { firebase_app } from "../../../data/config";
import { isMobile } from "react-device-detect";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchEngineMarketAdvisor from "../../../searchEngine/searchEngine";
import { faNavicon, faPowerOff } from "@fortawesome/free-solid-svg-icons";
import "./headerDesign.scss";
import { UserContext } from "../../../context/UserContext";
import { Link } from "react-router-dom";

const Header = ({ onClickMenu }) => {
  var stateMenu = localStorage.getItem("sidebar");

  if (stateMenu === null) {
    stateMenu = true;
  }

  const [sidebar, setSidebar] = useState(stateMenu);
  const user = useContext(UserContext);
  const Logout_From_Firebase = () => {
    firebase_app.auth().signOut();
  };

  useEffect(() => {
    window.localStorage.setItem("sidebar", sidebar);
  }, [sidebar]);

  return (
    <Fragment>
      <div className={isMobile ? "header-mobile" : "header"} style={{ zIndex: "1000" }}>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-1 text-center my-auto">
                {isMobile && (
                  <a href="#javascript" onClick={onClickMenu}>
                    <FontAwesomeIcon icon={faNavicon} style={{ color: "#54626e", fontSize: "20px" }} />
                  </a>
                )}
              </div>
              <div className="col text-center">
                <SearchEngineMarketAdvisor />
              </div>

              {user ? (
                <div className={"col-auto text-right"}>
                  <div className="btn btnSecondary text-center" onClick={Logout_From_Firebase} style={{ cursor: "pointer" }}>
                    {/* <FontAwesomeIcon icon={faPowerOff} /> */} Log Out
                  </div>{" "}
                </div>
              ) : (
                <div className={"col-auto text-right"}>
                  <Link to={`${process.env.PUBLIC_URL}/pages/signup`} className="btn btnSecondary">
                    Create Account
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Header;
