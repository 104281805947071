import { faMoneyBill, faHouse, faMagnifyingGlass, faScaleBalanced, faCreditCard, faCircleQuestion, faBook, faWallet } from "@fortawesome/free-solid-svg-icons";

export const MENUITEMS = [
  {
    title: "Home",
    icon: faHouse,
    type: "home",

    active: false,
    path: "/",
  },
  {
    title: "Portfolio",
    icon: faWallet,
    type: "home",

    active: false,
    path: "/myportfolio",
  },
  {
    title: "Subscription",
    icon: faCreditCard,
    type: "home",
    path: "/subscription",
    active: false,
  },
  {
    title: "Screener",
    icon: faMagnifyingGlass,
    type: "findAndCompare",
    path: "/screener",
    active: false,
  },

  {
    title: "Screener Lib",
    icon: faBook,
    type: "findAndCompare",
    path: "/screenerlib",
    active: false,
  },

  {
    title: "Compare Stock",
    icon: faScaleBalanced,
    type: "findAndCompare",
    path: "/comparative/new",
    active: false,
  },

  {
    title: "Dividends",
    icon: faMoneyBill,
    type: "findAndCompare",
    path: "/dividends",
    active: false,
  },

  {
    title: "FAQ",
    icon: faCircleQuestion,
    type: "tool",
    path: "/faq",
    active: false,
  },
];
