export const calculFinancialData = (fin, year, extraData) => {
  var totalRevenu = parseFloat(fin[year]["totalRevenue"]);
  var costOfRevenue = parseFloat(fin[year]["costOfRevenue"]);
  var operatingIncome = parseFloat(fin[year]["operatingIncome"]);
  var totalOperatingExpenses = parseFloat(fin[year]["totalOperatingExpenses"]);
  var grossProfit = parseFloat(fin[year]["grossProfit"]);
  // var opEx = parseFloat(fin[year]["totalOperatingExpenses"]);
  var netProfit = parseFloat(fin[year]["netIncome"]);
  var totalStockholderEquity = parseFloat(fin[year]["totalStockholderEquity"]);
  var ebit = parseFloat(fin[year]["ebit"]);
  var interestExpense = parseFloat(fin[year]["interestExpense"]);
  var currentAsset = parseFloat(fin[year]["totalCurrentAssets"]);
  var inventory = parseFloat(fin[year]["inventory"]);

  var currentLiab = parseFloat(fin[year]["totalCurrentLiabilities"]);
  var totalAssets = parseFloat(fin[year]["totalAssets"]);
  var totalLiab = parseFloat(fin[year]["totalLiab"]);
  var totalEquity = totalAssets - totalLiab;
  var netWorkingCapital = parseFloat(fin[year]["netWorkingCapital"]);
  var retainedEarnings = parseFloat(fin[year]["retainedEarnings"]);
  var cashAndShortTermInvestments = parseFloat(fin[year]["cashAndShortTermInvestments"]);
  var netReceivables = parseFloat(fin[year]["netReceivables"]);

  const grossMargin = Math.round((100 * 100 * (totalRevenu - costOfRevenue)) / totalRevenu) / 100;
  // Calcul Operating Margin

  let operatingMargin = Math.round((100 * 100 * operatingIncome) / totalRevenu) / 100;

  // Calcul Net Margin
  const netMargin = Math.round((100 * 100 * netProfit) / totalRevenu) / 100;
  // Calcul ROA
  const ROA = Math.round((100 * 100 * netProfit) / totalAssets) / 100;
  // Calcul ROE
  const ROE = Math.round((100 * 100 * netProfit) / totalStockholderEquity) / 100;

  const interestCoverageRatio = Math.round((100 * ebit) / Math.abs(interestExpense)) / 100;
  // console.log(year, "IC", ebit, interestCoverageRatio);
  // Quick Ratio
  var quickRatio = Math.round((100 * 100 * (currentAsset - inventory)) / currentLiab) / 100;
  if (isNaN(quickRatio)) {
    quickRatio = Math.round((100 * 100 * (cashAndShortTermInvestments + netReceivables)) / currentLiab) / 100;
  }
  if (isNaN(quickRatio)) {
    quickRatio = Math.round((100 * 100 * currentAsset) / currentLiab) / 100;
  }
  // Equity/Assets Ratio
  const eqAssetRatio = Math.round((100 * 100 * totalEquity) / totalAssets) / 100;

  // Altman Z Score
  // const retainedEarning = netProfit;

  const marketCap = extraData?.marketCap;
  if (isNaN(netWorkingCapital)) {
    netWorkingCapital = 0;
  }
  if (isNaN(retainedEarnings)) {
    retainedEarnings = 0;
  }
  const altmanZScore = Math.round(100 * (1.2 * (netWorkingCapital / totalAssets) + 1.4 * (retainedEarnings / totalAssets) + 3.3 * (ebit / totalAssets) + 0.6 * (marketCap / totalLiab) + 0.999 * (totalRevenu / totalAssets))) / 100;
  const priceEquity = Math.round((100 * marketCap) / totalEquity) / 100;
  const priceSales = Math.round((100 * marketCap) / totalRevenu) / 100;

  return {
    grossMargin,
    operatingMargin,
    netMargin,
    ROA,
    ROE,
    interestCoverageRatio,
    quickRatio,
    eqAssetRatio,
    altmanZScore,
    priceEquity,
    priceSales,
  };
};

export const calculFinancialDataAvg = (fin, dateAvg) => {
  const lengthAvg = dateAvg.length;
  var grossMarginAvg = 0;
  var operatingMarginAvg = 0;
  var netMarginAvg = 0;
  var ROAAvg = 0;
  var ROEAvg = 0;

  var interestCoverageRatioAvg = 0;
  var quickRatioAvg = 0;
  var eqAssetRatioAvg = 0;

  for (const year of dateAvg) {
    var { grossMargin, operatingMargin, netMargin, ROA, ROE, interestCoverageRatio, quickRatio, eqAssetRatio } = calculFinancialData(fin, year);
    grossMarginAvg = grossMarginAvg + grossMargin / lengthAvg;
    operatingMarginAvg = operatingMarginAvg + operatingMargin / lengthAvg;
    netMarginAvg = netMarginAvg + netMargin / lengthAvg;
    ROAAvg = ROAAvg + ROA / lengthAvg;
    ROEAvg = ROEAvg + ROE / lengthAvg;
    interestCoverageRatioAvg = interestCoverageRatioAvg + interestCoverageRatio / lengthAvg;
    quickRatioAvg = quickRatioAvg + quickRatio / lengthAvg;
    eqAssetRatioAvg = eqAssetRatioAvg + eqAssetRatio / lengthAvg;
  }

  grossMarginAvg = Math.round(100 * grossMarginAvg) / 100;
  operatingMarginAvg = Math.round(100 * operatingMarginAvg) / 100;
  netMarginAvg = Math.round(100 * netMarginAvg) / 100;
  ROAAvg = Math.round(100 * ROAAvg) / 100;
  ROEAvg = Math.round(100 * ROEAvg) / 100;
  interestCoverageRatioAvg = Math.round(100 * interestCoverageRatioAvg) / 100;
  quickRatioAvg = Math.round(100 * quickRatioAvg) / 100;
  eqAssetRatioAvg = Math.round(100 * eqAssetRatioAvg) / 100;

  return {
    lengthAvg,
    grossMarginAvg,
    operatingMarginAvg,
    netMarginAvg,
    ROAAvg,
    ROEAvg,
    interestCoverageRatioAvg,
    quickRatioAvg,
    eqAssetRatioAvg,
  };
};
