import { firebase_app } from "../data/config";

export const getFinancialDataQuarter = async (symbol) => {
  var financialData = {};
  await firebase_app
    .firestore()
    .collection("ticker")
    .doc(symbol)
    .collection("financial")
    .doc("quarter")
    .get()
    .then((doc) => {
      financialData = doc.data();
    });
  return financialData;
};

export const getFinancialData = async (symbol) => {
  var financialData = {};
  await firebase_app
    .firestore()
    .collection("ticker")
    .doc(symbol)
    .collection("financial")
    .doc("year")
    .get()
    .then((doc) => {
      financialData = doc.data();
    });
  return financialData;
};
export const getGeneralInfo = async (symbol) => {
  var informationTicker = {};
  await firebase_app
    .firestore()
    .collection("ticker")
    .doc(symbol)
    .collection("other")
    .doc("general")
    .get()
    .then((doc) => {
      informationTicker = doc.data();
    });
  return informationTicker;
};

export const getPopularity = async (symbol) => {
  var popularity = {};
  await firebase_app
    .firestore()
    .collection("stock")
    .doc(symbol)
    .collection("info")
    .doc("popularity")
    .get()
    .then((doc) => {
      popularity = doc.data();
    });
  return popularity;
};

export const getAverageIndustry = async (data) => {
  const sector = data.sector;
  const industry = data.industry.replace(/[^a-z0-9]/gi, "");

  var averageIndustry = {};

  await firebase_app
    .firestore()
    .collection("sector")
    .doc(sector)
    .collection(industry)
    .doc("average")
    .get()
    .then((doc) => {
      averageIndustry = doc.data();
    });
  return averageIndustry;
};

export const getBigMover = async () => {
  var bigGainer = [];
  var bigLooser = [];
  var tmp = {};

  await firebase_app
    .firestore()
    .collection("bigmover")
    .doc("up")
    .get()
    .then((doc) => {
      tmp = doc.data();
      Object.keys(tmp).map((key) => bigGainer.push(tmp[key]));
    });
  await firebase_app
    .firestore()
    .collection("bigmover")
    .doc("down")
    .get()
    .then((doc) => {
      tmp = doc.data();
      Object.keys(tmp).map((key) => bigLooser.push(tmp[key]));
    });

  return { bigGainer, bigLooser };
};
