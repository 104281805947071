import React, { Fragment, useState, useEffect } from "react";
import UserPanel from "./userPanel";
import { MENUITEMS } from "../../../components/common/sidebar-component/menu";
import { Link } from "react-router-dom";
import { translate } from "react-switch-lang";
import ContactLink from "./contactLink";
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Sidebar = (props) => {
  const [width, setWidth] = useState(0);

  const [mainmenu, setMainMenu] = useState(MENUITEMS);

  // useEffect(() => {
  //   window.addEventListener("resize", handleResize);
  //   handleResize();

  //   var currentUrl = window.location.pathname;

  //   // eslint-disable-next-line
  //   mainmenu.filter((items) => {
  //     if (items.path === currentUrl) setNavActive(items);
  //     if (!items.children) return false;
  //     // eslint-disable-next-line
  //     items.children.filter((subItems) => {
  //       if (subItems.path === currentUrl) setNavActive(subItems);
  //       if (!subItems.children) return false;
  //       // eslint-disable-next-line
  //       subItems.children.filter((subSubItems) => {
  //         if (subSubItems.path === currentUrl) {
  //           setNavActive(subSubItems);
  //           return true;
  //         } else {
  //           return false;
  //         }
  //       });
  //       return subItems;
  //     });
  //     return items;
  //   });

  //   const timeout = setTimeout(() => {
  //     const elmnt = document.getElementById("myDIV");
  //     const menuWidth = elmnt.offsetWidth;
  //     if (menuWidth > window.innerWidth) {
  //       setHideRightArrow(false);
  //       setHideLeftArrowRTL(false);
  //     } else {
  //       setHideRightArrow(true);
  //       setHideLeftArrowRTL(true);
  //     }
  //   }, 500);

  //   return () => {
  //     // eslint-disable-next-line
  //     window.removeEventListener("resize", handleResize);
  //     clearTimeout(timeout);
  //   };
  //   // eslint-disable-next-line
  // }, []);

  const handleResize = () => {
    setWidth(window.innerWidth - 310);
  };

  const setNavActive = (item) => {
    // eslint-disable-next-line
    MENUITEMS.filter((menuItem) => {
      // eslint-disable-next-line
      if (menuItem != item) menuItem.active = false;
      if (menuItem.children && menuItem.children.includes(item)) menuItem.active = true;
      if (menuItem.children) {
        // eslint-disable-next-line
        menuItem.children.filter((submenuItems) => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
            return true;
          } else {
            return false;
          }
        });
      }
      return menuItem;
    });
    item.active = !item.active;
    setMainMenu({ mainmenu: MENUITEMS });
  };

  // Click Toggle menu
  const toggletNavActive = (item) => {
    if (!item.active) {
      MENUITEMS.forEach((a) => {
        if (MENUITEMS.includes(item)) a.active = false;
        if (!a.children) return false;
        a.children.forEach((b) => {
          if (a.children.includes(item)) {
            b.active = false;
          }
          if (!b.children) return false;
          b.children.forEach((c) => {
            if (b.children.includes(item)) {
              c.active = false;
            }
          });
        });
      });
    }
    item.active = !item.active;
    setMainMenu({ mainmenu: MENUITEMS });
  };

  return (
    <Fragment>
      <div id="sidebar" className={isMobile ? "page-sidebar open" : "page-sidebar"}>
        <div className="" style={{ height: "5vh", paddingTop: "1vh" }}>
          <div className="text-center">
            <Link to={`${process.env.PUBLIC_URL}/`}>
              <h3 style={{ color: "white" }}>
                <span>
                  {"market"}
                  <span style={{ opacity: "0.6" }}>{"advisor"}</span>
                </span>
              </h3>
            </Link>
          </div>
        </div>
        <div className="sidebar custom-scrollbar">
          <UserPanel />

          <div>
            <div className="scroll-menu" style={isMobile ? { overflow: "auto", height: "50vh", width: "100%" } : { overflow: "auto", height: "55vh", width: "100%" }}>
              <ul className="sidebar-menu" id="myDIV">
                {/*  Home */}
                <div className="separator"></div>
                {MENUITEMS.map((menuItem, i) => (
                  <li className={`${menuItem.active ? "active" : ""}`} key={i}>
                    {menuItem.type === "home" ? (
                      <Link to={`${process.env.PUBLIC_URL}${menuItem.path}`} className={`sidebar-header ${menuItem.active ? "active" : ""}`} onClick={() => toggletNavActive(menuItem)}>
                        <FontAwesomeIcon icon={menuItem.icon} style={{ height: "18px", width: "18px" }} />
                        <span>{props.t(menuItem.title)}</span>
                      </Link>
                    ) : (
                      ""
                    )}
                  </li>
                ))}
                <div className="separator"></div>
                {/* <p style={{ fontSize: "18px", marginLeft: "18px", marginBottom: "0", color: "#acacac" }}>{"Find and Compare"}</p> */}
                {/* Find & Compare  */}
                {MENUITEMS.map((menuItem, i) => (
                  <li className={`${menuItem.active ? "active" : ""}`} key={i}>
                    {menuItem.type === "findAndCompare" ? (
                      <Link to={`${process.env.PUBLIC_URL}${menuItem.path}`} className={`sidebar-header ${menuItem.active ? "active" : ""}`} onClick={() => toggletNavActive(menuItem)}>
                        <FontAwesomeIcon icon={menuItem.icon} style={{ height: "18px", width: "18px" }} />
                        <span>{props.t(menuItem.title)}</span>
                      </Link>
                    ) : (
                      ""
                    )}
                  </li>
                ))}
                {/* Tools */}
                <div className="separator"></div>
                {/* <p className=" f-14 text-center" style={{ color: "white", marginBottom: "0" }}>
              {"Tools"}
            </p> */}
                {MENUITEMS.map((menuItem, i) => (
                  <li className={`${menuItem.active ? "active" : ""}`} key={i}>
                    {menuItem.type === "tool" ? (
                      <Link to={`${process.env.PUBLIC_URL}${menuItem.path}`} className={`sidebar-header ${menuItem.active ? "active" : ""}`} onClick={() => toggletNavActive(menuItem)}>
                        <FontAwesomeIcon icon={menuItem.icon} style={{ height: "18px", width: "18px" }} />
                        <span>{props.t(menuItem.title)}</span>
                      </Link>
                    ) : (
                      ""
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <div
                className="menu-bottom"
                style={
                  isMobile ? { position: "absolute", bottom: "0%", width: "100%", height: "19vh", borderTop: "#555 solid 2px" } : { position: "absolute", bottom: "0%", width: "100%", height: "23vh", borderTop: "#555 solid 2px" }
                }
              >
                <div className=" text-center p-t-10 m-l-20 m-r-20 ">
                  <a className="btn btnSecondary btn-lg btn-block" href={`${process.env.PUBLIC_URL}/subscription`} style={{ color: "white" }}>
                    Get Started
                  </a>
                </div>
                <ContactLink />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default translate(Sidebar);
