import React, { Fragment } from "react";
import { faInstagram, faTwitter, faTelegram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const ContactLink = () => {
  return (
    <Fragment>
      <div className=" text-center p-t-20 ">
        <a className="m-5 maincolortext" href="https://twitter.com/MarketAdvisorio" rel="noopener noreferrer" target="_blank" >
          <FontAwesomeIcon icon={faTwitter} size="2xl" />
        </a>

        <a className="m-5 maincolortext" href="https://www.instagram.com/marketadvisor.io/" rel="noopener noreferrer" target="_blank" >
          <FontAwesomeIcon icon={faInstagram} size="2xl" />
        </a>
        <a className="m-5 maincolortext" href="https://www.t.me/MarketAdvisorio/" rel="noopener noreferrer" target="_blank" >
          <FontAwesomeIcon icon={faTelegram} size="2xl" />
        </a>
      </div>
    </Fragment>
  );
};

export default ContactLink;
